import styles from "./App.module.css";
import { useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Auth from "./auth/auth";
import Menu from "./auth/menu/menu";
import Data from "./dataviewer/dataviewer";
import Header from "./header";
import Interface from "./components/interface/Interface";
import ErrorBoundary from "./pages/error";
import Account from "./auth/menu/account";

function App() {
  const [showAccount, setShowAccount] = useState(false);
  return (
    <div className={styles.app} rel="preload">
      <ErrorBoundary>
        <BrowserRouter>
          <Header />
          <Account />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/user" element={<Menu />} /> */}
            <Route path="/auth" element={<Auth />} />
            <Route path="/data" element={<Data showAccount={showAccount} />} />
            <Route path="/gui" element={<Interface />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
