import { warning_icon_red, scan_icon } from "../../utils/assets";
import { MarkerF, InfoWindow } from "@react-google-maps/api";

export default function Scan({
  data,
  infoWindow,
  scanInfoWindow,
  setScanInfoWindow,
  setActive,
  zoom,
  minZoomVisibility,
}) {
  const handleScanHover = async (scan, index) => {
    setScanInfoWindow(scan.id);
    setActive(index);
  };

  return (
    <>
      {infoWindow &&
        data.map((scan, index) => (
          <>
            <MarkerF
              onMouseOver={() => handleScanHover(scan, index)}
              onClick={() => handleScanHover(scan, index)}
              position={{
                lat: scan.data.location._lat,
                lng: scan.data.location._long,
              }}
              name={scan.data.name}
              key={scan.id}
              icon={{
                url: scan.data.warning ? warning_icon_red : scan_icon,
                anchor: { x: 15, y: 15 },
              }}
              visible={zoom > minZoomVisibility ? true : false}
            />
            {scanInfoWindow === scan.id && (
              <InfoWindow
                options={{
                  headerContent: scan.data.name,
                }}
                position={{
                  lat: scan.data.location._lat,
                  lng: scan.data.location._long,
                }}
              >
                <div>
                  <div>
                    {new Date(
                      scan.data.time_of_scan.seconds * 1000
                    ).toDateString()}
                  </div>

                  <div>Comment: {scan.data.comment}</div>
                </div>
              </InfoWindow>
            )}
          </>
        ))}
    </>
  );
}
