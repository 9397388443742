import { Polyline, MarkerF, InfoWindow } from "@react-google-maps/api";

import styles from "./map.module.css";

export default function Line({
  handleLineDelete,
  lines,
  lineID,
  lineFilter,
  setLineFilter,
  setLineID,
  zoom,
  minZoomVisibility,
}) {
  const handleLineSelector = (line) => {
    if (lineFilter.includes(line)) {
      setLineFilter([]);
    } else {
      setLineFilter([line]);
    }
  };

  return (
    <>
      {lines &&
        lines.map((line) => (
          <>
            <Polyline
              editable={false}
              title={"test"}
              options={{
                strokeOpacity: 0.8,
                strokeWeight: 5,
                strokeColor: lineFilter.includes(line) ? "#15a8ab" : "#FFFFFF",
              }}
              onRightClick={() => setLineID(line.id)}
              onClick={() => handleLineSelector(line)}
              path={[
                { lat: line.data.start._lat, lng: line.data.start._long },
                { lat: line.data.end._lat, lng: line.data.end._long },
              ]}
              visible={zoom > minZoomVisibility ? true : false}
            />
            <MarkerF
              visible={false} // Work around as info windows require a marker
            />
            {lineID === line.id && (
              <InfoWindow
                visible={true}
                options={{
                  headerContent: "Line Editor",
                }}
                position={{
                  lat: (line.data.start._lat + line.data.end._lat) / 2,
                  lng: (line.data.start._long + line.data.end._long) / 2,
                }}
              >
                <div className={styles.line_editor}>
                  <input
                    className={styles.editor_name}
                    type="text"
                    defaultValue={line.data.name}
                  />
                  <button>Edit</button>
                  <button
                    className={styles.editor_delete}
                    onClick={() => handleLineDelete(line)}
                  >
                    Delete
                  </button>
                </div>
              </InfoWindow>
            )}
          </>
        ))}
    </>
  );
}
