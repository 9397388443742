import { app } from "../auth/firebase.config.js";
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  doc,
} from "firebase/firestore";

import Message from "../utils/message";

const db = getFirestore(app);

export async function get_user_farms(userid) {
  let farms = [];
  try {
    const docSnap = await getDoc(doc(db, "users", userid));
    if (docSnap) {
      await Promise.all(
        (
          await docSnap.data().farms
        ).map(async (id) => {
          farms.push({
            id: id,
            data: await get_farm(id),
          });
        })
      );
    }
  } catch (error) {
    console.log(error);
    Message("Error loading farms", "warning");
  }
  return farms;
}

export async function get_farm(farmid) {
  try {
    const docSnap = await getDoc(doc(db, "farms", farmid));
    if (docSnap) {
      return { id: docSnap.id, data: docSnap.data() };
    } else {
      console.log("Not found");
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function get_scans(farmid) {
  let scans = [];
  try {
    const docSnap = await getDocs(collection(db, "farms", farmid, "scans"));
    if (docSnap) {
      docSnap.forEach((doc) =>
        scans.push({
          id: doc.id,
          data: doc.data(),
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
  return scans;
}

export async function get_lines(farmid) {
  let lines = [];
  try {
    const docSnap = await getDocs(collection(db, "farms", farmid, "lines"));
    if (docSnap) {
      docSnap.forEach((doc) =>
        lines.push({
          id: doc.id,
          data: doc.data(),
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
  return lines;
}

export async function get_areas(farmid) {
  let area = [];
  try {
    const docSnap = await getDocs(collection(db, "farms", farmid, "area"));
    if (docSnap) {
      docSnap.forEach((doc) =>
        area.push({
          id: doc.id,
          data: doc.data(),
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
  return area;
}

export async function get_multi(farmid) {
  let lines = [];
  try {
    const docSnap = await getDocs(collection(db, "farms", farmid, "multiline"));
    if (docSnap) {
      docSnap.forEach((doc) =>
        lines.push({
          id: doc.id,
          data: doc.data(),
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
  return lines;
}

export async function get_scan(farmid, scanid) {
  try {
    const docSnap = await getDoc(doc(db, "farms", farmid, "scans", scanid));
    if (docSnap) {
      console.log(docSnap.data());
      return docSnap.data();
    } else {
      console.log("error");
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}
