import { useEffect, useState } from "react"
import styles from "./chart.module.css"
import { Line, Scatter, Bar } from "react-chartjs-2"
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage"
import axios from "axios"
import { options, initialChartData } from "./config"
import Table from "./table"
import { Chart as ChartJS } from "chart.js/auto"
import annotationPlugin from "chartjs-plugin-annotation"
import Tooltip from "../../utils/tooltip"

ChartJS.register(annotationPlugin)

export default function Chart({ farm, scan, scans }) {
  const storage = getStorage()
  const [select, setSelect] = useState("length")
  const [chartData, setChartData] = useState(initialChartData) // For multiple scans

  // For individual scans
  const [length, setLength] = useState(initialChartData)
  const [barLength, setBarLength] = useState(initialChartData)
  const [barLengthFull, setBarLengthFull] = useState(initialChartData)
  const [width, setWidth] = useState(initialChartData)
  const [barWidth, setBarWidth] = useState(initialChartData)
  const [barWidthFull, setBarWidthFull] = useState(initialChartData)

  // Temporary for Measurement
  const [measuredLength, setMeasuredLength] = useState(null)
  const [measuredWidth, setMeasuredWidth] = useState(null)

  async function calculateAverage(numbers) {
    return numbers.reduce((sum, number) => sum + number, 0) / numbers.length
  }

  function convertDate(date) {
    const year = parseInt(date.toString().slice(0, 2)) + 2000 // extract year
    const month = parseInt(date.toString().slice(2, 4)) - 1 // extract month ( subtract 1 because months are 0-based)
    const day = parseInt(date.toString().slice(4)) // extract day
    return new Date(year, month, day).toDateString()
  }

  function sortArray(labels, data) {
    let date = labels.slice()
    let av = data.average.slice()
    let max = data.max.slice()
    let min = data.min.slice()

    let newlabels = []
    let newAverage = []
    let newMax = []
    let newMin = []

    for (let i = 0; i < labels.length; i++) {
      let index = date.indexOf(Math.min(...date))
      newlabels.push(date[index])
      newAverage.push(av[index])
      newMax.push(max[index])
      newMin.push(min[index])
      av.splice(index, 1)
      max.splice(index, 1)
      min.splice(index, 1)
      date.splice(index, 1)
    }
    return {
      sortedDate: newlabels,
      sortedData: {
        average: newAverage,
        min: newMax,
        max: newMin,
      },
    }
  }

  const dataBins = (data, bin_size) => {
    const min = Math.min(...data)
    const max = Math.max(...data)
    const binSize = bin_size

    let bins = []
    let labels = []
    for (let i = min; i <= max; i += binSize) {
      const bin_count = data.filter(
        (value) => value >= i - 1 && value < i - 1 + binSize
      ).length

      bins.push(bin_count)

      labels.push(`${i - 1} - ${i - 1 + binSize}`)
    }
    return {
      labels: labels,
      data: bins,
    }
  }

  const dataBinsFull = async (data, bin_size, dataRange) => {
    const min = 0
    const max = dataRange

    const binSize = bin_size

    let bins = []
    let labels = []
    for (let i = min; i <= max; i += binSize) {
      const bin_count = data.filter(
        (value) => value >= i && value < i + binSize
      ).length

      bins.push(bin_count / max)
      labels.push(`${i}`)
    }
    return {
      labels: labels,
      data: bins,
    }
  }

  async function Measurements() {
    console.log("here")

    const dataDirectoryRef = ref(
      storage,
      `${farm.id}/m0PbCRUXuQoCSGGWVzN7/data/csvjson.json`
    )

    const url = await getDownloadURL(dataDirectoryRef)
    const response = await axios.get(url)

    let data = await response.data

    const binLengthFull = await dataBinsFull(
      data.map((data) => data.length),
      5,
      150
    )

    const binWidthFull = await dataBinsFull(
      data.map((data) => data.width),
      2,
      70
    )
    return {
      length: {
        bar: {
          label: "Measured",
          data: binLengthFull.data,
          fill: true,
          backgroundColor: "rgb(135, 197, 171)",
        },
        line: {
          label: "Measured",
          data: binLengthFull.data,
          type: "line",
          fill: true,
          borderColor: "rgb(135, 197, 171)",
        },
      },
      width: {
        bar: {
          label: "Measured",
          data: binWidthFull.data,
          fill: true,
          backgroundColor: "rgb(135, 197, 171)",
        },
        line: {
          label: "Measured",
          data: binWidthFull.data,
          type: "line",
          fill: true,
          borderColor: "rgb(135, 197, 171)",
        },
      },
    }
  }

  async function fetchIndividual() {
    if (farm !== null && scan !== null) {
      const { length, width } = await Measurements()
      console.log(length)

      const dataDirectoryRef = ref(storage, `${farm.id}/${scan.id}/data/`)
      listAll(dataDirectoryRef)
        .then((res) =>
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((url) => {
              axios.get(url).then(async (response) => {
                let data = response.data
                let depth = data.map((data) => data.frame_depth)

                const binLength = dataBins(
                  data.map((data) => data.length),
                  5
                )
                setBarLength({
                  labels: binLength.labels,
                  datasets: [
                    {
                      label: "Count",
                      data: binLength.data,
                      fill: true,
                      backgroundColor: "rgb(54, 162, 235, 0.5)",
                    },
                    {
                      label: "Count",
                      data: binLength.data,
                      type: "line",
                      fill: true,
                      borderColor: "rgb(54, 162, 235)",
                    },
                  ],
                })

                const binLengthFull = await dataBinsFull(
                  data.map((data) => data.length),
                  5,
                  150
                )
                setBarLengthFull({
                  labels: binLengthFull.labels,
                  datasets: [
                    {
                      label: "Scanned",
                      data: binLengthFull.data,
                      fill: true,
                      backgroundColor: "rgb(54, 162, 235, 0.5)",
                    },
                    {
                      label: "Scanned",
                      data: binLengthFull.data,
                      type: "line",
                      fill: true,
                      borderColor: "rgb(54, 162, 235)",
                    },
                    length.bar,
                    length.line,
                  ],
                })

                setLength({
                  labels: depth,
                  datasets: [
                    {
                      label: "Mussel Length",
                      data: data.map((data) => data.length),
                    },
                  ],
                })

                const binWidth = dataBins(
                  data.map((data) => data.width),
                  2
                )
                setBarWidth({
                  labels: binWidth.labels,
                  datasets: [
                    {
                      label: "Count",
                      data: binWidth.data,
                      fill: true,
                      backgroundColor: "rgb(54, 162, 235, 0.5)",
                    },
                    {
                      label: "Count",
                      data: binWidth.data,
                      type: "line",
                      fill: true,
                      borderColor: "rgb(54, 162, 235)",
                    },
                  ],
                })

                const binWidthFull = await dataBinsFull(
                  data.map((data) => data.width),
                  2,
                  70
                )
                setBarWidthFull({
                  labels: binWidthFull.labels,
                  datasets: [
                    {
                      label: "Scanned",
                      data: binWidthFull.data,
                      fill: true,
                      backgroundColor: "rgb(54, 162, 235, 0.5)",
                    },
                    {
                      label: "Scanned",
                      data: binWidthFull.data,
                      type: "line",
                      fill: true,
                      borderColor: "rgb(54, 162, 235)",
                    },
                    width.bar,
                    width.line,
                  ],
                })

                setWidth({
                  labels: depth,
                  datasets: [
                    {
                      label: "Mussel Width",
                      data: data.map((data) => data.width),
                    },
                  ],
                })
              })
            })
          })
        )
        .catch((error) => console.log(error))
    }
  }

  async function fetchChartData() {
    console.log("Fetching Data")
    if (farm !== null && scan !== null && scans !== null) {
      const dates = []
      const average = []
      const min = []
      const max = []

      for (const item of scans) {
        const dataDirectoryRef = ref(storage, `/${farm.id}/${item.id}/data`)
        const dataDirectoryList = await listAll(dataDirectoryRef)

        for (const itemRef of dataDirectoryList.items) {
          const url = await getDownloadURL(itemRef)
          const response = await axios.get(url)
          const data = response.data
          const scanDates = data.map((entry) => entry.datetime_scan.slice(0, 5))

          let scanData = null
          switch (select) {
            case "width":
              scanData = data.map((entry) => entry.width)
              break
            default:
              scanData = data.map((entry) => entry.length)
          }

          // console.log(scanDates)
          dates.push(await calculateAverage(scanDates))
          // console.log(dates)
          average.push(await calculateAverage(scanData))
          min.push(Math.min(...scanData))
          max.push(Math.max(...scanData))
        }
      }

      return {
        labels: dates,
        data: {
          average: average,
          min: min,
          max: max,
        },
      }
    }
  }

  useEffect(() => {
    if (farm !== null && scan !== null) {
      const handler = async () => {
        const { labels, data } = await fetchChartData()
        const { sortedDate, sortedData } = sortArray(labels, data)

        setChartData({
          labels: sortedDate.map((time) => convertDate(time)),
          datasets: [
            {
              data: sortedData.max,
              borderColor: "white",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderWidth: 2,
              tension: 0.2,
              fill: 1,
            },
            {
              label: "Average",
              data: sortedData.average,
              borderColor: "white",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderWidth: 2,
              tension: 0.2,
              fill: 1,
            },
            {
              data: sortedData.min,
              borderColor: "white",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderWidth: 2,
              tension: 0.2,
              fill: 1,
            },
          ],
        })
      }
      if (scans.length > 1) {
        handler()
      }
    }
  }, [select])

  useEffect(() => {
    fetchIndividual()
  }, [scan])

  return (
    <div className={styles.chart_container}>
      {/* {console.log(scans)} */}

      {/* {scans.length > 1 && (
        <div>
          <div>
            <h1 className={styles.title}>Mussel {select} over Time</h1>
            <select
              className={styles.selector}
              onChange={(e) => setSelect(e.target.value)}>
              <option value={"length"}>Length</option>
              <option value={"width"}>Width</option>
            </select>
          </div>
          <div className={styles.main_chart}>
            <Line
              data={chartData}
              options={options(`${select} (mm)`, "Time")}
            />
          </div>
        </div>
      )} */}

      {/* <h1 className={styles.title}>Key Parameters Relative to Depth</h1> */}
      <h1 className={styles.subtitle}>Estimated Mussel Length Summary</h1>
      <div className={styles.individual_container}>
        <div className={styles.chart}>
          <div className={styles.summary}>
            {/* <h1 className={styles.title}>Length Summary</h1> */}

            <div className={styles.row_main}>
              <div className={styles.column}>
                <h2 className={styles.subtitle}>Average mussel length (mm)</h2>
                <h2 className={styles.subtitle}></h2>
                <div className={styles.data}>
                  <p className={styles.main}>37.1</p>
                  <p className={styles.units}>mm</p>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <h2 className={styles.subtitle}>
                    Average mussel count per m
                  </h2>
                  <Tooltip
                    tip={
                      "Total estimated mussel count per metre based on the 1/2 circular density"
                    }
                  />
                </div>

                <div className={styles.data}>
                  <p className={styles.main}>194</p>
                  {/* <div className={styles.column}>
                    <p className={styles.units}>/ 77</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.row}>
                <h2 className={styles.subtitle}>Size Distribution</h2>
                <Tooltip tip={"Size categories based on width distribution"} />
              </div>

              <div className={styles.distribution}>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Small</h2>
                  <p className={styles.sub}>32 mm</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Medium</h2>
                  <p className={styles.sub}>36 mm</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Large</h2>
                  <p className={styles.sub}>42 mm</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.chart_mobile}>
          <h1 className={styles.subtitle}>Length Distribution</h1>
          <Bar data={barLength} options={options("Mussel Count", "")} />
        </div>

        <div className={styles.chart}>
          {/* <h1 className={styles.subtitle}>Length (mm)</h1> */}

          <Scatter
            data={length}
            options={options("Length (mm)", "Depth (m)")}
          />
        </div>
      </div>
      <h1 className={styles.subtitle}>
        Mussel Length Distribution: Scanned vs. Measured
      </h1>
      <div className={styles.wide_chart}>
        <Bar
          data={barLengthFull}
          options={options("Count/Total Count", "Length (mm)", 0.1)}
        />
      </div>

      <h1 className={styles.subtitle}>Estimated Mussel Width Summary</h1>
      <div className={styles.individual_container}>
        <div className={styles.chart}>
          <div className={styles.summary}>
            {/* <h1 className={styles.title}>Width Summary</h1> */}

            <div className={styles.row_main}>
              <div className={styles.column}>
                <h2 className={styles.subtitle}>Average mussel width (mm)</h2>
                <div className={styles.data}>
                  <p className={styles.main}>17</p>
                  <p className={styles.units}>mm</p>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <h2 className={styles.subtitle}>
                    Average mussel count per m
                  </h2>
                  <Tooltip
                    tip={
                      "Total estimated mussel count per metre based on the 1/2 circular density"
                    }
                  />
                </div>

                <div className={styles.data}>
                  <p className={styles.main}>194</p>
                  {/* <div className={styles.column}>
                    <p className={styles.units}>/ 77</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.row}>
                <h2 className={styles.subtitle}>Size Distribution</h2>
                <Tooltip tip={"Size categories based on width distribution"} />
              </div>

              <div className={styles.distribution}>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Small</h2>
                  <p className={styles.sub}>15 mm</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Medium</h2>
                  <p className={styles.sub}>16 mm</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.column}>
                  <h2 className={styles.subtitle}>Large</h2>
                  <p className={styles.sub}>19 mm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.chart_mobile}>
          <h1 className={styles.subtitle}>Width Distribution</h1>
          <Bar
            data={barWidth}
            options={options("Mussel Count", "Width (mm)")}
          />
        </div>
        <div className={styles.chart}>
          {/* <h1 className={styles.subtitle}>Width (mm)</h1> */}
          <Scatter
            data={width}
            options={options("Width (mm)", "Depth (m)", 0.1)}
          />
        </div>
      </div>

      <h1 className={styles.subtitle}>
        Mussel Width Distribution: Scanned vs. Measured
      </h1>
      <div className={styles.wide_chart}>
        <Bar
          data={barWidthFull}
          options={options("Count/Total Count", "Width (mm)")}
        />
      </div>
      <h1 className={styles.subtitle}>Tabular Data</h1>
      <div className={styles.table_container}>
        <Table farm={farm} scan={scan} />
      </div>
    </div>
  )
}
