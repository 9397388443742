// Authorisation popup
import styles from "./auth.module.css"
import { useEffect, useState } from "react"
import SignUp from "./signup"
import SignIn from "./signin"
import Message from "../utils/message"
import { google } from "../utils/assets"
import { signInWithGooglePopup } from "./firebase.config.js"

import { useNavigate } from "react-router-dom"
import { auth } from "./firebase.config.js"
import { onAuthStateChanged } from "firebase/auth"

export default function Auth() {
  const [account, setAccount] = useState(true)
  const [user, setUser] = useState(null)
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState("")

  const navigate = useNavigate()
  let uid = null

  onAuthStateChanged(auth, (user) => {
    if (user) {
      uid = user.uid
      console.log("Redirect")
      navigate(`/data`, { replace: true })
    } else {
      console.log("User signed out")
    }
  })

  const handleAccount = async (e) => {
    e.preventDefault()
    setAccount(!account)
  }

  const handleGoogleSignin = async () => {
    await signInWithGooglePopup()
      .then((userCredential) => {
        const user = userCredential.user
        localStorage.setItem("user", user.accessToken)
        console.log("Google signin")
        setMessage("Sign in with Google")
        setStatus("success")
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        setMessage("Sign in with Google unavailable")
        setStatus("warning")
      })
  }

  return (
    <div>
      {message && <Message content={message} status={status} />}
      <div className={styles.form_container}>
        <div className={styles.inner_container}>
          {account ? (
            <SignIn user={user} setUser={setUser} />
          ) : (
            <SignUp user={user} setUser={setUser} />
          )}

          {/* <div className={styles.buttons_container}>
            <div
              className={styles.google_login_button}
              onClick={handleGoogleSignin}
            >
              <img src={google}></img>
              <span className={styles.google_prompt}>Log in with Google</span>
            </div>
          </div> */}

          <p className={styles.sign_up_label}>
            {account ? "Don't have an account?  " : "Have an Account?  "}
            {account ? (
              <span className={styles.sign_up_link} onClick={handleAccount}>
                Sign up
              </span>
            ) : (
              <span className={styles.sign_up_link} onClick={handleAccount}>
                Sign in
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}
