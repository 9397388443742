import { useEffect, useState } from "react";
import styles from "./imageviewer.module.css";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
  updateMetadata,
} from "firebase/storage";
import { warning_icon } from "../utils/assets";

import { get_scans } from "./farms.js";

import { getFirestore, doc, updateDoc, Timestamp } from "firebase/firestore";
import { app } from "../auth/firebase.config.js";

import Loader from "../utils/loader";

export default function ImageViewer({ farm, scan, scans }) {
  const storage = getStorage();

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  // Image metadata
  const [metadata, setMetadata] = useState(false);
  const [comment, setComment] = useState("");
  const [warning, setWarning] = useState(false);

  const getImages = async () => {
    if (farm !== null && scan !== null) {
      const pathReference = ref(storage, `/${farm.id}/${scan.id}/photos`);
      setImages([]);
      console.log("Retrieving image list");
      listAll(pathReference)
        .then((res) => {
          res.items.map((itemRef) => {
            getMetadata(itemRef)
              .then((metadata) => {
                let meta = metadata;
                getDownloadURL(itemRef).then((url) => {
                  setImages((img) => [...img, { url: url, metadata: meta }]);
                });
              })
              .catch((error) => {
                console.log(error);
              });
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    getImages();
  }, [scan]);

  const handleImageClick = (e, index) => {
    console.log(e);
    if (selected === index) {
      setSelected(null);
      setMetadata(null);
    } else {
      setSelected(index);
      setMetadata(index);
    }
  };

  const handleUpdate = async (e, metadata) => {
    e.preventDefault();
    console.log(metadata);
    const db = getFirestore(app);

    // Update image metadata
    const metaRef = ref(storage, metadata.fullPath);
    await updateMetadata(metaRef, {
      customMetadata: {
        comment: comment,
        warning: warning,
      },
    });

    await updateDoc(doc(db, "farms", farm.id, "scans", scan.id), {
      comment: comment,
      warning: warning,
    });

    // Get updated scan list
    const scans_updated = await get_scans(farm.id);

    // Check if any scans have an alert
    let alert = false;
    for (const item of scans_updated) {
      if (item.data.warning) {
        alert = true;
      }
    }
    await updateDoc(doc(db, "farms", farm.id), {
      date_updated: Timestamp.now(),
      warning: alert,
    });
    getImages();
  };

  return (
    <div className={styles.image_container}>
      {loading && <Loader />}
      <div className={styles.image_grid}>
        {images.map((url, index) => (
          <div
            className={styles.popup}
            style={
              selected === index
                ? { gridRow: "1", gridColumn: "1/-1", transition: "all 0.2s" }
                : null
            }
          >
            <img
              id={index}
              className={styles.images}
              src={url.url}
              alt=""
              onClick={(e) => handleImageClick(e, index)}
            />

            {url.metadata.customMetadata?.warning === "true" ? (
              <img className={styles.metadata_icon} src={warning_icon} />
            ) : null}

            {metadata === index && (
              <form
                className={styles.metadata}
                onSubmit={(e) => handleUpdate(e, url.metadata)}
              >
                <label>Comment*</label>
                <div className={styles.metadata_row}>
                  <textarea
                    onChange={(e) => setComment(e.target.value)}
                    required
                  ></textarea>
                </div>

                <div className={styles.metadata_row}>
                  <label>Alert</label>
                  <input
                    type="checkbox"
                    onChange={(e) => setWarning(e.target.checked)}
                  />
                </div>
                <button className={styles.metadata_button} type="submit">
                  Update
                </button>
              </form>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
