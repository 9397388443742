import { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "./firebase.config.js";
import styles from "./auth.module.css";
// import { getFirestore, getDoc, doc } from "firebase/firestore";
import Message from "../utils/message.js";

function SignIn({ user, setUser }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  // const getUserMetaData = async () => {
  //   console.log(user);
  //   if (user) {
  //     const db = getFirestore(app);
  //     const docSnap = await getDoc(doc(db, `users`, user.uid));
  //     console.log(docSnap);
  //     if (docSnap.exists()) {
  //       console.log(docSnap.data());
  //     } else {
  //       console.log("Does not exist");
  //     }
  //   }
  // };

  const signin = async (e) => {
    console.log("Sign in");
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("user", user.accessToken);
        // if (!user.emailVerified) {
        //   console.log("Please verify email");
        //   sendEmailVerification(auth.currentUser).then(() => {
        //     console.log("Verification email sent");
        //   });
        // }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage("Invalid email or password");
        setStatus("warning");
      });
  };

  const passwordReset = async () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Check emails");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage("Unable to reset password");
        setStatus("warning");
      });
  };

  return (
    <div>
      {message && <Message content={message} status={status} />}
      <p className={styles.subtitle}>Kia ora</p>
      <p className={styles.title}>Sign In</p>
      <form className={styles.form} onSubmit={signin}>
        <input
          type="email"
          className={styles.input}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          type="password"
          className={styles.input}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <p className={styles.page_link}>
          <span className={styles.page_link_label} onClick={passwordReset}>
            Forgot Password?
          </span>
        </p>
        <button type="submit" className={styles.form_btn} onClick={signin}>
          Sign In
        </button>
      </form>
      {/* <button className={styles.form_btn} onClick={getUserMetaData}>Users</button> */}
    </div>
  );
}

export default SignIn;
