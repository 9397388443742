import { useState } from "react";
import styles from "./auth.module.css";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { app, auth } from "./firebase.config.js";
import Message from "../utils/message.js";

function SignUp({ user, setUser }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);

  const addUser = async (userObj) => {
    setUser(userObj);
    const db = getFirestore(app);
    try {
      console.log(userObj.uid);
      await setDoc(doc(db, "users", userObj.uid), {
        uid: userObj.uid,
        company: null,
        farms: [
          "0NNH06G0bCIn12IXdKmX",
          "6AjSLasmdpTN0S01c5cM",
          "sdy81aX8dPwiZAONtYIS",
        ],
        admin: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const signup = async (e) => {
    console.log("Sign in");
    e.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        addUser(user);
        localStorage.setItem("user", user.accessToken);
        // if (!user.emailVerified) {
        //   console.log("Please verify email")
        //   sendEmailVerification(auth.currentUser).then(() => {
        //     console.log("Verification email sent")
        //   })
        // }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage("Invalid email or password");
        setStatus("warning");
      });
  };

  const passwordReset = async () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Check emails");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage("Unable to reset password");
        setStatus("warning");
      });
  };

  return (
    <div>
      {message && <Message content={message} status={status} />}
      <p className={styles.subtitle}>Welcome</p>
      <p className={styles.title}>Create an Account</p>
      <form className={styles.form} onSubmit={signup}>
        <input
          type="email"
          className={styles.input}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          type="password"
          className={styles.input}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <p className={styles.page_link}>
          <span className={styles.page_link_label} onClick={passwordReset}>
            Forgot Password?
          </span>
        </p>
        <button type="submit" className={styles.form_btn}>
          Sign Up
        </button>
      </form>
    </div>
  );
}

export default SignUp;
