import styles from "./map.module.css"
import { warning_icon, stats } from "../../utils/assets.js"

export default function Info({ farm, data }) {
  const farm_info = farm.data
  if (data.length > 0) {
    return (
      <div className={styles.info}>
        {farm_info.data.warning && (
          <div className={styles.warning}>
            <img
              className={styles.warning_icon}
              src={warning_icon}
              alt=""></img>
            <h2 className={styles.warning_message}>Warning</h2>
          </div>
        )}

        <div className={styles.stat_column}>
          <div className={styles.stat_row}>
            <img className={styles.stat_icon} src={stats} alt=""></img>
            <h2>Overview</h2>
          </div>
          <p>Number of Scans: {data.length}</p>
        </div>

        <p>Average Mussel:</p>
        <div className={styles.stat_container}>
          <div className={styles.stat_card}>
            <p className={styles.stat_title}>Length</p>
            <div className={styles.stat_row}>
              <p className={styles.stat_main}>37 mm</p>
              {/* <p className={styles.stat_secondary}>+5%</p> */}
            </div>
          </div>

          <div className={styles.stat_card}>
            <p className={styles.stat_title}>Width</p>
            <div className={styles.stat_row}>
              <p className={styles.stat_main}>17 mm</p>
              {/* <p className={styles.stat_secondary}>+2%</p> */}
            </div>
          </div>
        </div>
        {/* <p>View All statistics -</p> */}

        {/* <button className={styles.info_button}>New Scan</button> */}
      </div>
    )
  } else {
    return (
      <div className={styles.info}>
        <p>No Data Available</p>
        <button className={styles.info_button}>Upload Data</button>
      </div>
    )
  }
}
