import React, { useState } from "react";
import styles from "./dataviewer.module.css";

import ImageViewer from "./imageviewer";

import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import Uploader from "./uploader.js";
import Message from "../utils/message";
import { auth, app } from "../auth/firebase.config.js";
import { close_icon, dropdown_icon } from "../utils/assets.js";
import Chart from "./table/chart.js";
import { doc, getDoc, getFirestore } from "firebase/firestore";

// Tidying up - individual components
import Scans from "./scans/scans.js";
import Map from "./map/map.js";

// Info window example - https://github.com/visgl/react-google-maps/blob/main/examples/markers-and-infowindows/src/app.tsx

export default function Data({ showAccount }) {
  const db = getFirestore(app);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(null); // Current farm selected
  const [currentScan, setCurrentScan] = useState(null); // Current scan id selected
  const [admin, setAdmin] = useState(false);

  // const [farms, setFarms] = useState([]);
  const [scans, setScans] = useState([]);
  const [active, setActive] = useState(false); // Sets active scan across map view and scans side panel

  // Filters
  const [lineFilter, setLineFilter] = useState([]);

  // Routes
  const [select, setSelect] = useState("map");
  const [upload, setUpload] = useState(false);

  // Mobile
  const [height, setHeight] = useState(window.screen.height - 130);

  onAuthStateChanged(auth, async (user) => {
    if (!user) {
      console.log("Redirect");
      navigate(`/auth`, { replace: true });
    } else {
      const data = await getDoc(doc(db, "users", user.uid));
      if (data) {
        setAdmin(data.data().admin);
      }
    }
  });

  const handleExpand = (e) => {
    e.stopPropagation();
    if (height <= 80) {
      setHeight(window.screen.height - 130);
    } else {
      setHeight(80);
    }
  };

  return (
    <div className={styles.data_container}>
      <div className={styles.left_container}>
        {!current && <Message content="Please select a farm" />}
        {select === "chart" && (
          <Chart farm={current} scan={currentScan} scans={scans} />
        )}
        {select === "image" && (
          <ImageViewer farm={current} scan={currentScan} scans={scans} />
        )}
        {select === "map" && (
          <Map
            current={current}
            lineFilter={lineFilter}
            setLineFilter={setLineFilter}
            setCurrent={setCurrent}
            setActive={setActive}
            setScans={setScans}
          />
        )}
      </div>

      {current && (
        <div
          className={styles.farm_container}
          style={{
            position: window.screen.width < 900 && "absolute",
            top: window.screen.width < 900 && `${height}px`,
          }}
        >
          <div className={styles.header_container}>
            <div
              className={styles.swipe}
              onTouchEnd={(e) => {
                handleExpand(e);
                e.stopPropagation();
              }}
            >
              <div className={styles.swipe_icon}></div>
            </div>
            <div
              className={styles.close_scan_viewer}
              onClick={() => {
                setCurrent(null);
                setSelect("map");
              }}
            >
              <img className={styles.close_icon} src={close_icon}></img>
            </div>
            {current && (
              <>
                <div className={styles.header_row}>
                  <p className={styles.farm_name}>{current.data.name}</p>
                  <div>
                    <p className={styles.farm_info}>
                      Lat: {Math.round(current.data.location._lat * 100) / 100}
                    </p>
                    <p className={styles.farm_info}>
                      Long:{" "}
                      {Math.round(current.data.location._long * 100) / 100}
                    </p>
                  </div>
                </div>
                {admin && (
                  <>
                    <div
                      className={styles.new_farm_row}
                      onClick={(e) => {
                        setUpload(!upload);
                        e.stopPropagation();
                      }}
                    >
                      <img
                        className={styles.upload_icon}
                        src={dropdown_icon}
                        style={{
                          transform: !upload
                            ? "rotate(-90deg)"
                            : "rotate(0deg)",
                        }}
                      ></img>
                      <p>Upload Data</p>
                    </div>
                    {upload && (
                      <Uploader farmid={current} scanid={currentScan} />
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <Scans
            lineFilter={lineFilter}
            setLineFilter={setLineFilter}
            farm={current}
            scans={scans}
            setScans={setScans}
            setCurrentScan={setCurrentScan}
            setSelect={setSelect}
            active={active}
            setActive={setActive}
          />
        </div>
      )}
    </div>
  );
}
