// Charts styling

import { background } from "../../utils/assets"

export const options = (labely, labelx, tickCount) => {
  return {
    tension: 0.3,
    annotation: {
      drawTime: "afterDatasetsDraw",
      annotations: [
        {
          drawTime: "afterDraw",
          id: "a-line-1",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: "50",
          borderColor: "red",
          borderWidth: 2,
        },
      ],
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(255, 255, 255, 0.2)",
        },
        gridLines: {
          offsetGridLines: true,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.8)",
          font: {
            family: "Montserrat",
            size: 12,
            fontWeight: 400,
          },
        },
        title: {
          display: true,
          text: labelx,
          color: "rgba(255, 255, 255, 0.8)",
          font: {
            family: "Montserrat",
            fontWeight: 400,
            size: 15,
          },
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(255, 255, 255, 0.2)",
        },

        border: {
          display: false,
          color: "white",
        },
        ticks: {
          stepSize: tickCount,
          // precision: 0.01,
          color: "rgba(255, 255, 255, 0.8)",
          font: {
            family: "Montserrat",
            size: 12,
          },
        },
        title: {
          display: true,
          text: labely,
          color: "rgba(255, 255, 255, 0.8)",
          font: {
            family: "Montserrat",
            size: 15,
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        color: "rgba(255, 255, 255, 0.4)",
        title: {
          color: "rgba(255, 255, 255, 0.4)",
        },
        font: {
          color: "rgba(255, 255, 255, 0.4)",
          family: "Montserrat",
          size: 12,
        },
        labels: {
          color: "rgba(255, 255, 255, 0.8)",
          font: {
            family: "Montserrat",
            size: 12,
          },
          filter: function (item) {
            return item.datasetIndex % 2 === 0
          },
        },
      },
      title: {
        label: "Title",
        display: true,
      },
    },
  }
}

export const initialChartData = {
  labels: [],
  datasets: [{ data: [] }],
  scales: {
    x: {
      grid: {
        display: false,
        color: "rgba(255, 255, 255, 0.2)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 0.4)",
        font: {
          family: "Montserrat",
          size: 12,
        },
      },
    },
    y: {
      grid: {
        display: false,
        color: "rgba(255, 255, 255, 0.2)",
      },
      border: {
        display: false,
        color: "white",
      },
      ticks: {
        stepSize: 10,
        color: "rgba(255, 255, 255, 0.4)",
        font: {
          family: "Montserrat",
          size: 12,
        },
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      color: "rgba(255, 255, 255, 0.4)",
      title: {
        color: "rgba(255, 255, 255, 0.4)",
      },
      font: {
        color: "rgba(255, 255, 255, 0.4)",
        family: "Montserrat",
        size: 12,
      },
      labels: {
        color: "rgba(255, 255, 255, 0.1)",
        font: {
          family: "Montserrat",
          size: 12,
        },
      },
    },
    title: {
      display: false,
    },
  },
}
