// User profile area, user can:
// View current details
// Edit profile details
// Update email
// Delete account

import { useState, useEffect } from "react"
import styles from "./account.module.css"
import { auth } from "../firebase.config.js"
import { onAuthStateChanged, deleteUser, updatePassword } from "firebase/auth"
import SignUserOut from "../signout"
import { useNavigate } from "react-router-dom"
import { close_icon, dropdown_icon, menu_icon } from "../../utils/assets.js"
import Checkbox from "../../utils/checkbox.js"

import { doc, getDoc, updateDoc, getFirestore } from "firebase/firestore"
import { app } from "../firebase.config.js"

export default function Account() {
  // const user = auth.currentUser
  const navigate = useNavigate()
  const db = getFirestore(app)
  const [user, setUser] = useState(null)
  const [agree, setAgree] = useState(false)
  const [admin, setAdmin] = useState(null)
  const [select, setSelect] = useState(false)

  // Dropdown options
  const [reset, setReset] = useState(false)
  const [early, setEarly] = useState(false)
  const [deleteSection, setDeleteSection] = useState(false)

  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        console.log("Redirect")
        // navigate(`/auth`, { replace: true });
      } else {
        setUser(user)
        const data = await getDoc(doc(db, "users", user.uid))
        if (data) {
          setAdmin(data.data().admin)
        }
      }
    })
  }, [user, admin])

  const editAccount = async () => {
    console.log("edit")
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    if (password === confirmPassword) {
      updatePassword(user, password)
        .then(() => {
          console.log("Password Updated")
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleExperimental = async () => {
    await updateDoc(doc(db, "users", user.uid), {
      admin: !admin,
    })
    setAdmin(!admin)
  }

  const handleAccountDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      console.log("confirm")
      const userAccount = auth.currentUser
      deleteUser(userAccount)
        .then(() => {
          console.log("Account Deleted")
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div className={styles.account_container}>
      {user && (
        <div className={styles.user_button}>
          <img
            className={styles.menu_icon}
            src={!select ? menu_icon : close_icon}
            onClick={() => setSelect(!select)}></img>
        </div>
      )}

      {select && (
        <div className={styles.account_main}>
          <h1 className={styles.title}>Account Options</h1>

          <section>
            <div>
              <div className={styles.account_row_reset}>
                <label>User</label>
                <label>{user?.email}</label>
              </div>
            </div>

            <div className={styles.account_row}>
              <SignUserOut setUser={setUser} setSelect={setSelect} />
            </div>
          </section>

          <hr className={styles.partition} />

          <section>
            <span
              className={styles.account_row}
              onClick={() => setReset(!reset)}>
              <img
                className={styles.icon}
                src={dropdown_icon}
                style={{
                  transform: !reset && "rotate(-90deg)",
                }}></img>
              <h1>Password Reset</h1>
            </span>
            <form
              onSubmit={handlePasswordReset}
              className={styles.account_info}
              style={{ display: reset ? "block" : "none" }}>
              <div className={styles.account_row_reset}>
                <div className={styles.account_row}>
                  <div className={styles.account_column}>
                    <label>New Password</label>
                    <input
                      className={styles.account_input}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      required
                    />
                  </div>
                </div>

                <div className={styles.account_row}>
                  <div className={styles.account_column}>
                    <div className={styles.account_column}>
                      <label>Repeated</label>
                    </div>
                    <input
                      className={styles.account_input}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password"
                      required
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className={styles.account_button}>
                Change Password
              </button>
            </form>
          </section>

          <hr className={styles.partition} />

          <span className={styles.account_row} onClick={() => setEarly(!early)}>
            <img
              className={styles.icon}
              src={dropdown_icon}
              style={{
                transform: !early && "rotate(-90deg)",
              }}></img>
            <h1>Early Access</h1>
          </span>
          {admin ? (
            <section style={{ display: early ? "block" : "none" }}>
              <p>Disable early access features</p>
              <button
                className={styles.account_button}
                onClick={() => handleExperimental()}>
                Disable
              </button>
            </section>
          ) : (
            <section style={{ display: early ? "block" : "none" }}>
              <p>Sign up for early access to experimental features &#127793;</p>

              <h2>Terms and Conditions</h2>

              <p>
                Experimental features may have bugs or issues. ŪWAI Robotics is
                not responsible for any potential data loss or issues arising
                from using these features.
              </p>
              <p>
                Users agree to provide relevant feedback on experimental
                features.
              </p>
              <p>Users can opt out of the early access program at any time.</p>

              <span
                className={styles.account_row}
                onClick={() => setAgree(!agree)}>
                <Checkbox checked={agree} />
                <p style={{ margin: "5px" }}>Agree to terms and conditions</p>
              </span>

              <h2>Feedback Instructions</h2>
              <p>
                By signing up for early access you agree to provide feedback.
                For bug reports and enhancement requests, please submit a{" "}
                <a href="https://github.com/uwai-robotics/Customer-Website/issues">
                  GitHub Issue
                </a>{" "}
                or email{" "}
                <a href="mailto:info@uwairobotics.co.nz">
                  info@uwairobotics.co.nz
                </a>
              </p>
              <button
                className={styles.account_button}
                onClick={() => handleExperimental()}
                disabled={!agree}>
                Enable
              </button>
            </section>
          )}

          <hr className={styles.partition} />

          <span
            className={styles.account_row}
            onClick={() => setDeleteSection(!deleteSection)}>
            <img
              className={styles.icon}
              src={dropdown_icon}
              style={{
                transform: !deleteSection && "rotate(-90deg)",
              }}></img>
            <h1>Delete Account</h1>
          </span>
          <section style={{ display: deleteSection ? "block" : "none" }}>
            <p>Caution - This action cannot be undone</p>
            <button
              className={styles.delete_account}
              onClick={() => handleAccountDelete()}>
              Delete Account
            </button>
          </section>
        </div>
      )}
    </div>
  )
}
