import { useState } from "react"
import { upload_icon } from "../utils/assets"
import styles from "./uploader.module.css"
import { app } from "../auth/firebase.config.js"
import { getStorage, ref, uploadBytes } from "firebase/storage"

import {
  getFirestore,
  collection,
  addDoc,
  GeoPoint,
  Timestamp,
} from "firebase/firestore"

import Checkbox from "../utils/checkbox"
import Tooltip from "../utils/tooltip"

export default function Uploader({ farmid, scanid }) {
  const storage = getStorage()
  const [files, setFiles] = useState(null)

  const handleNewScanFromFile = async () => {
    const db = getFirestore(app)
    try {
      const docRef = await addDoc(collection(db, "farms", farmid.id, "scans"), {
        name: farmid.data.name,
        location: new GeoPoint(
          farmid.data.location._lat,
          farmid.data.location._long
        ),
        time_of_scan: Timestamp.now(),
      })
      return docRef
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const handleUpload = async (e) => {
    e.preventDefault()
    if (scanid === null) {
      scanid = await handleNewScanFromFile()
    }

    for (const file of files) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        try {
          const storageRef = ref(
            storage,
            `${farmid.id}/${scanid.id}/photos/${file.name}`
          )
          uploadBytes(storageRef, file).then(() => {
            console.log("Uploaded a blob or file!")
          })
        } catch (e) {
          console.log(e)
        }
      } else if (file.type === "text/csv" || file.type === "application/json") {
        try {
          const storageRef = ref(
            storage,
            `${farmid.id}/${scanid.id}/data/${file.name}`
          )
          uploadBytes(storageRef, file).then(() => {
            console.log("Uploaded a blob or file!")
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  return (
    <div className={styles.upload_container}>
      <form onSubmit={handleUpload} className={styles.upload_form}>
        <input
          className={styles.upload_input}
          id="uploader"
          onChange={(e) => setFiles(e.target.files)}
          type="file"
          accept=".jpg, .jpeg, .png, .csv, .json"
          multiple
        />

        <div className={styles.inner}>
          <img className={styles.upload_icon} src={upload_icon}></img>
          <button
            className={styles.upload_button}
            onClick={handleUpload}
            type="submit"
            disabled={farmid && files ? false : true}>
            Upload Files
          </button>

          <div className={styles.requirements_container}>
            <div className={styles.requirements}>
              <Checkbox checked={files ? true : false} /> File(s) Selected{" "}
              <Tooltip
                tip={
                  "Drag and drop files (.csv,.jpeg) or click in white region"
                }
              />
            </div>
            <div className={styles.requirements}>
              <Checkbox checked={farmid ? true : false} /> Farm*
            </div>
            <div className={styles.requirements}>
              <Checkbox checked={scanid ? true : false} /> Scan{" "}
              <Tooltip
                tip={
                  "Select farm and scan to upload data. If not selected a new scan will be created"
                }
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
