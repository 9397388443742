import { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "./firebase.config.js";
import styles from "./auth.module.css";
import { useNavigate } from "react-router-dom";
import Message from "../utils/message";

function SignUserOut({ setUser, setSelect }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleSignOut = async () => {
    signOut(auth)
      .then(() => {
        localStorage.setItem("user", null);
        navigate(`/`, { replace: true });
        setUser(null);
        setSelect(null);
        setMessage("User signed out");
        setStatus("success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {message && <Message content={message} status={status} />}
      <button className={styles.signout_button} onClick={handleSignOut}>
        Sign Out
      </button>
    </>
  );
}

export default SignUserOut;
