import styles from "./controls.module.css"
import Checkbox from "../../utils/checkbox"
import { useState } from "react"
import { add_icon, map_icon } from "../../utils/assets"

export default function Controls({ maptype, setMapType }) {
  const [control, setControl] = useState(null)

  return (
    <div
      className={control ? styles.map_controls : styles.map_controls_closed}
      onClick={() => setControl(!control)}>
      {/* <h1 className={styles.controls_title}>Controls</h1>
      <div className={styles.controls_row} onClick={() => setControl("farm")}>
        <p className={styles.option}>Add New Scan Location</p>
        <Checkbox checked={control === "farm"} />
      </div>

      <div className={styles.controls_row} onClick={() => setControl("scan")}>
        <p className={styles.option}>Add New Scan</p>
        <Checkbox checked={control === "scan"} />
      </div>
      {control === "scan" && (
        <div className={styles.controls_column}>
          <p className={styles.prompt}>Select Scan type</p>
          <p className={styles.sub_option}>Mussel Scan</p>
          <p className={styles.sub_option}>Scallop Scan</p>
          <p className={styles.sub_option}>biosecurity Scan</p>
        </div>
      )}
      <hr className={styles.controls_hr} /> */}
      <h1 className={styles.controls_title}>
        <img className={styles.control_icon} src={map_icon}></img>Map Options
      </h1>
      <div
        className={styles.controls_row}
        onClick={(e) => {
          setMapType("roadmap")
          e.stopPropagation()
        }}>
        <p className={styles.option}>Default View</p>
        <Checkbox checked={maptype === "roadmap"} />
      </div>
      <div
        className={styles.controls_row}
        onClick={(e) => {
          setMapType("satellite")
          e.stopPropagation()
        }}>
        <p className={styles.option}>Satellite View</p>
        <Checkbox checked={maptype === "satellite"} />
      </div>
    </div>
  )
}
