import styles from "./table.module.css"
import { useEffect, useState } from "react"
import { CompactTable } from "@table-library/react-table-library/compact"
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage"
import axios from "axios"
import Checkbox from "../../utils/checkbox"

const BASELINE_THEME = {
  Table: "overflow: auto; border-radius: 10px;",
  Header: "",
  Body: "",
  BaseRow: `
    font-size: 16px;
  `,
  HeaderRow: `
    color: white;
    background-color: rgb(104, 111, 120);

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  `,
  Row: `
    background-color: rgba(255, 255, 255, 0.2);
    color: white;

    &.disabled {
      color: grey;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    &:not(:last-of-type) > .td {
      border-bottom: 1px solid grey;
    }
  `,
  BaseCell: `
    padding: 10px 12px;
  `,
  HeaderCell: `
    font-weight: bold;
    border-bottom: 1px solid grey;

    .resizer-handle {
      background-color: grey;
    }

    svg,
    path {
      fill: grey;
    }
  `,
  Cell: `
    &:focus {
      outline: dotted;
      outline-width: 1px;
      outline-offset: -1px;
    },
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  `,
}

export default function Table({ farm, scan }) {
  const [nodes, setNodes] = useState([])
  const [verbose, setVerbose] = useState(true)

  const COLUMNS = [
    { label: "id", renderCell: (item) => item.line_id, hide: verbose },
    { label: "Latitude", renderCell: (item) => item.latitude, hide: verbose },
    { label: "Longitude", renderCell: (item) => item.longitude, hide: verbose },
    { label: "Length (mm)", renderCell: (item) => item.length },
    { label: "Width (mm)", renderCell: (item) => item.width },
    {
      label: "Slice Density (per m)",
      renderCell: (item) => item.slice_density,
    },
    {
      label: "1/2 Circle Density (per m)",
      renderCell: (item) => item.circle_density,
    },
    {
      label: "Datetime",
      renderCell: (item) => item.datetime_proc,
      hide: verbose,
    },
    { label: "Frame_depth", renderCell: (item) => item.frame_depth },
    {
      label: "Frame_number",
      renderCell: (item) => item.frame_number,
      hide: verbose,
    },
    { label: "Scan_date", renderCell: (item) => item.datetime_scan },
  ]

  const handleDataFetch = async () => {
    const storage = getStorage()

    if (farm !== null && scan !== null) {
      const dataDirectoryRef = ref(storage, `${farm.id}/${scan.id}/data/`)
      listAll(dataDirectoryRef)
        .then((res) =>
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((url) => {
              axios.get(url).then((response) => {
                let data = response.data
                setNodes(data)
              })
            })
          })
        )
        .catch((error) => console.log(error)) // No data found
    }
  }

  useEffect(() => {
    handleDataFetch()
  }, [scan])

  return (
    <div className={styles.table_container}>
      <div className={styles.show_all} onClick={() => setVerbose(!verbose)}>
        <p>{!verbose ? "Hide" : "Show All"}</p>
        <Checkbox checked={!verbose ? true : false} />{" "}
      </div>
      <CompactTable
        styles={styles.table}
        columns={COLUMNS}
        data={{ nodes }}
        theme={BASELINE_THEME}
      />
    </div>
  )
}
