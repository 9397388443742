export default function Filter(p1Lat, p1Lng, p2Lat, p2Lng, pointLat, pointLng) {
  function latLngToMeters(lat1, lng1, lat2, lng2) {
    const R = 6371000; // Earth radius in meters

    const radLat1 = (lat1 * Math.PI) / 180;
    const radLng1 = (lng1 * Math.PI) / 180;
    const radLat2 = (lat2 * Math.PI) / 180;
    const radLng2 = (lng2 * Math.PI) / 180;

    const deltaLat = radLat2 - radLat1;
    const deltaLng = radLng2 - radLng1;

    // Accurate conversion considering the varying length of a degree of longitude
    const x = deltaLng * R * Math.cos(radLat1);
    const y = deltaLat * R;

    return { x, y };
  }

  // Function to calculate the perpendicular distance from a point to a line segment in 2D
  function distanceToLineSegment(p1, p2, point) {
    const A = point.x - p1.x;
    const B = point.y - p1.y;
    const C = p2.x - p1.x;
    const D = p2.y - p1.y;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;

    if (len_sq !== 0) {
      param = dot / len_sq;
    }

    let xx, yy;

    if (param < 0) {
      xx = p1.x;
      yy = p1.y;
    } else if (param > 1) {
      xx = p2.x;
      yy = p2.y;
    } else {
      xx = p1.x + param * C;
      yy = p1.y + param * D;
    }

    const dx = point.x - xx;
    const dy = point.y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

  // Function to check if a point is within 1 meter of a line segment P1P2
  function isPointNearLine(
    p1Lat,
    p1Lng,
    p2Lat,
    p2Lng,
    pointLat,
    pointLng,
    tolerance = 5
  ) {
    // Convert lat/lng to meters
    const p1 = { x: 0, y: 0 }; // Reference point (P1)
    const p2 = latLngToMeters(p1Lat, p1Lng, p2Lat, p2Lng);
    const point = latLngToMeters(p1Lat, p1Lng, pointLat, pointLng);

    // Calculate the distance from the point to the line segment
    const distance = distanceToLineSegment(p1, p2, point);

    // Check if the distance is within the tolerance
    return distance <= tolerance;
  }

  const result = isPointNearLine(
    p1Lat,
    p1Lng,
    p2Lat,
    p2Lng,
    pointLat,
    pointLng
  );
  return result;
}
