import { useState, useCallback } from "react";
import styles from "./header.module.css";
import { auth } from "./auth/firebase.config.js";
import { onAuthStateChanged } from "firebase/auth";
import SignUserOut from "./auth/signout";
import { Link } from "react-router-dom";
import { menu_icon, user_icon } from "./utils/assets.js";
import Account from "./auth/menu/account.js";

export default function Header() {
  let [user, setUser] = useState(null);
  const [selected, setSelected] = useState(false);

  // const [visible, setVisible] = useState(false);

  onAuthStateChanged(auth, (user_) => {
    if (user_) {
      setUser(user_);
    }
  });

  // const handleUser = useCallback(() => {
  //   setSelected(!selected);
  // }, []);

  return (
    <div className={styles.header_top_panel}>
      <div className={styles.logo_block}>
        <img className={styles.logo_image}></img>
        <p className={styles.logo}>ŪWAI Robotics</p>
      </div>
      {console.log(selected)}
      <div className={styles.header_row}>
        {/* // <Link className={styles.user_button} to={"/user"}> */}
        {/* <div className={styles.user_button}>
          <img
            className={styles.icon}
            src={menu_icon}
            onClick={() => setSelected(false)}
          ></img>
        </div> */}

        {/* // </Link> */}
        {/* {user && <SignUserOut setUser={setUser} />} */}
        {/* <div className={styles.header_column}>
          <p>{user.displayName && `Welcome ${user.displayName}`}</p>
          <div>{user.emailVerified && "Verified"}</div>
        </div> */}
        {/* {user && <img className={styles.profile_photo} alt=""></img>} */}
      </div>

      {selected && <Account />}
    </div>
  );
}
