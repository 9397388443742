import styles from "./scans.module.css";
import {
  arrow_icon,
  dropdown_icon,
  filter_icon,
  images_icon,
  menu_icon,
  scan_icon,
  stats,
  warning_icon,
} from "../../utils/assets";
import { useState } from "react";

import * as React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";

import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { app } from "../../auth/firebase.config.js";

import { get_scans } from "../farms";

import Filter from "./filter";

const dateInitalise = [
  {
    startDate: new Date("May 19, 2023"),
    endDate: new Date(Date.now() + 3600 * 1000 * 24),
    key: "selection",
  },
];

export default function Scans({
  lineFilter,
  setLineFilter,
  farm,
  scans,
  setScans,
  setCurrentScan,
  setSelect,
  active,
  setActive,
}) {
  const [menu, setMenu] = useState(false);
  const [filter, setFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [lineDropDown, setLineDropDown] = useState(false);

  const [addComment, setAddComment] = useState(null);
  const [comment, setComment] = useState(null);
  const [warning, setWarning] = useState(null);

  // Filter options
  const [date, setDate] = useState(dateInitalise);

  React.useEffect(() => {
    setDate(dateInitalise);
  }, [scans]);

  const menuHandler = (e, index, info) => {
    e.stopPropagation();
    if (index === menu) {
      setMenu(false);
    } else {
      setMenu(index);
      setCurrentScan(info);
    }
  };

  const activeHandler = (index, info) => {
    setActive(index);
    setCurrentScan(info);
    if (index === active) {
      // setActive(false)
      // setCurrentScan(null)
    } else {
      setActive(index);
      setCurrentScan(info);
      setMenu(false);
    }
  };

  const handleFilter = (scan) => {
    const scanDate = new Date(scan.data.time_of_scan.seconds * 1000);
    if (scanDate <= date[0].startDate) {
      console.log(false);
      return false;
    } else if (scanDate >= date[0].endDate) {
      console.log(false);
      return false;
    } else {
      return true;
    }
  };

  const handleLineFilter = (scan) => {
    if (lineFilter.length === 0) {
      return true;
    }
    let lineResult = false;
    for (const line of lineFilter) {
      const result = Filter(
        line.data.start._lat,
        line.data.start._long,
        line.data.end._lat,
        line.data.end._long,
        scan.data.location._lat,
        scan.data.location._long
      );

      if (result === true) {
        lineResult = result;
        return lineResult;
      }
    }
    return lineResult;
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setDate(dateInitalise);
    setFilter(false);
    setLineFilter([]);
  };

  const handleComment = async (e, scan) => {
    e.preventDefault();
    console.log(comment);
    console.log(warning);
    const db = getFirestore(app);
    await updateDoc(doc(db, "farms", farm.id, "scans", scan.id), {
      comment: comment,
      warning: warning,
    });
    setScans(await get_scans(farm.id));
  };

  return (
    <div className={styles.scan_list}>
      {scans.length > 0 && (
        <>
          <h3>Scans</h3>

          <div className={styles.filter_container}>
            <div
              className={styles.filter_main}
              onClick={() => setFilter(!filter)}
            >
              <div className={styles.filter_row}>
                <img className={styles.filter_icon} src={filter_icon}></img>
                <p>Filter</p>
              </div>
              <p className={styles.clear} onClick={(e) => handleReset(e)}>
                clear
              </p>
            </div>
            <div
              className={styles.filter_options}
              style={{ height: !filter && "0px" }}
            >
              <div className={styles.filter_container}>
                <div className={styles.filter_panel}>
                  <img
                    className={styles.filter_icon}
                    src={dropdown_icon}
                    onClick={() => setDateFilter(!dateFilter)}
                    style={{
                      transform: !dateFilter && "rotate(-90deg)",
                    }}
                  ></img>
                  <p>Date Range</p>
                </div>
                <div
                  className={styles.calendar}
                  style={{ height: !dateFilter && "0px" }}
                >
                  <DateRange
                    className={[
                      styles.rdrCalendarWrapper,
                      styles.rdrDateDisplayWrapper,
                    ]}
                    editableDateInputs={false}
                    onChange={(item) => {
                      setDate([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                  />
                </div>
              </div>

              <div className={styles.filter_container}>
                <div className={styles.filter_panel}>
                  <img
                    className={styles.filter_icon}
                    src={dropdown_icon}
                    onClick={() => setLineDropDown(!lineDropDown)}
                    style={{
                      transform: !lineDropDown && "rotate(-90deg)",
                    }}
                  ></img>
                  <p>Line</p>
                </div>
                <div
                  className={styles.line_container}
                  style={{ height: !lineDropDown && "0px" }}
                >
                  <p className={styles.instructions}>Click on line to select</p>
                  {lineDropDown &&
                    lineFilter.map((line) => (
                      <div className={styles.line_selected}>
                        <p>Line ID: {line.id}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {scans &&
        scans
          .filter((scan) => handleFilter(scan))
          .filter((scan) => handleLineFilter(scan))
          .map((info, index) => (
            <div
              className={
                index === active
                  ? styles.scan_container_active
                  : styles.scan_container
              }
              style={{ backgroundColor: info.data.warning && "#f1655c" }}
              onClick={() => activeHandler(index, info)}
            >
              <img
                className={styles.icon}
                src={info.data.warning ? warning_icon : scan_icon}
              ></img>
              <div className={styles.scan_column}>
                <label className={styles.scan_labels}>Scan ID</label>
                <p className={styles.scan_info}>{info.data.name}</p>

                <label className={styles.scan_labels}>Date</label>
                <p className={styles.scan_info}>
                  {new Date(
                    info.data.time_of_scan.seconds * 1000
                  ).toDateString()}
                </p>
              </div>

              <div className={styles.scan_column_buttons}>
                <div className={styles.comment_container}>
                  <p onClick={() => setAddComment(!addComment)}>Comment</p>

                  {addComment && (
                    <form onSubmit={(e) => handleComment(e, info)}>
                      <div className={styles.filter_row}>
                        <label>Warning</label>
                        <input
                          type="checkbox"
                          defaultChecked={info.data.warning}
                          onChange={(e) => {
                            setWarning(e.target.checked);
                            e.stopPropagation();
                          }}
                        ></input>
                      </div>
                      <textarea
                        defaultValue={info.data.comment}
                        placeholder="Add a comment to scan"
                        maxLength={500}
                        required
                        onChange={(e) => {
                          setComment(e.target.value);
                          e.stopPropagation();
                        }}
                      ></textarea>

                      {comment && <button type="submit">Update</button>}
                    </form>
                  )}
                </div>
                <div className={styles.filter_row_border}>
                  <div
                    className={styles.option}
                    onClick={() => {
                      setSelect("image");
                      activeHandler(index, info);
                    }}
                  >
                    Images
                  </div>
                  <img
                    className={styles.menu_icon_inverted}
                    src={arrow_icon}
                  ></img>
                </div>

                <div className={styles.filter_row_border}>
                  <div
                    className={styles.option}
                    onClick={() => {
                      setSelect("chart");
                      activeHandler(index, info);
                    }}
                  >
                    Data
                  </div>
                  <img
                    className={styles.menu_icon_inverted}
                    src={arrow_icon}
                  ></img>
                </div>
              </div>

              <div
                className={styles.menu}
                onClick={(e) => menuHandler(e, index, info)}
              >
                <img className={styles.menu_icon} src={menu_icon} />
                {index === menu && (
                  <div className={styles.scan_options}>
                    <div className={styles.option}>Download</div>
                    <div className={styles.option}>Edit</div>
                    <div className={styles.option}>Delete</div>
                  </div>
                )}
              </div>
            </div>
          ))}
    </div>
  );
}
