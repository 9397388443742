// import { Request } from "zeromq"

// const HOST = "127.0.0.1"

export function Alt() {
  //   const topic_telem_port = "10102"

  //   const sock = new zmq.Pull()

  //   sock.connect("tcp://127.0.0.1:10102")
  //   //   console.log("Worker connected to port 3000")

  //   for await (const [msg] of sock) {
  //     console.log("work: %s", msg.toString())
  //   }

  return "ALT_TEXT"
}
