import { main } from "../utils/assets";
import styles from "./home.module.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className={styles.home_container}>
      <div className={styles.home_inner_container}>
        <h1 className={styles.home_title} rel="preload">
          Fully Autonomous Underwater Scanning
        </h1>
        <h2 className={styles.home_subtitle}>
          A commitment to innovation and sustainability
        </h2>
        <Link className={styles.home_button} to="/auth">
          Get Started
        </Link>
      </div>

      <div className={styles.main_container}>
        <img className={styles.main_image} src={main}></img>
      </div>
    </div>
  );
}

export default Home;
