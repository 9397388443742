// User interface
// Example: https://www.behance.net/gallery/80233665/Personal-Project-Drone-HUD?tracking_source=search_projects|drone+ui+hud&l=22

import styles from "./interface.module.css"
import { Alt } from "./telemetry"

function Interface() {
  return (
    <div className={styles.interface_container}>
      <img
        className={styles.stream}
        src="http://127.0.0.1:8085/100_1280_720_15.mjpg"
      />
      <div className={styles.interface_hud}>
        <svg
          width="600"
          height="600"
          viewBox="0 0 600 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="299.5"
            cy="297.5"
            r="297"
            stroke="#CDCDCD"
            stroke-opacity="0.2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M184.595 402.369L191.935 395.029C166.612 368.73 150.799 333.209 150.029 294H5.01494C5.00499 294.999 5 295.999 5 297C5 354.702 21.5664 408.534 50.2025 454H44.312C16.2092 408.33 0 354.558 0 297C0 295.999 0.00490406 294.999 0.0146895 294H0V289H0.104598C1.59843 231.937 19.0263 178.842 48.1025 134H54.0837C24.4454 178.625 6.62974 231.782 5.10638 289H150.013C150.531 249.396 166.397 213.494 191.935 186.971L184.595 179.631L188.131 176.095L195.463 183.427C221.812 157.817 257.538 141.804 297 141.029V131H302V141.013C341.604 141.531 377.506 157.397 404.029 182.935L410.869 176.095L414.405 179.631L407.573 186.463C433.399 213.034 449.466 249.142 449.987 289H594.894C593.37 231.782 575.555 178.625 545.916 134H551.897C580.974 178.842 598.402 231.937 599.895 289H600V294H599.985C599.995 294.999 600 295.999 600 297C600 354.558 583.791 408.33 555.688 454H549.798C578.434 408.534 595 354.702 595 297C595 295.999 594.995 294.999 594.985 294H449.971C449.196 333.462 433.183 369.188 407.573 395.537L414.405 402.369L410.869 405.905L404.029 399.065C377.506 424.603 341.604 440.469 302 440.987V451H297V440.971C257.538 440.196 221.812 424.183 195.463 398.573L188.131 405.905L184.595 402.369ZM445 291C445 371.081 380.081 436 300 436C219.919 436 155 371.081 155 291C155 210.919 219.919 146 300 146C380.081 146 445 210.919 445 291Z"
            fill="white"
            fill-opacity="0.4"
          />

          <text d="M29.7614 306.455H31.8636L36.8068 318.528H36.">
            Circle Text
          </text>
          <foreignObject
            // align="center"
            x="480"
            y="300"
            width="600"
            height="600">
            VEL_TEXT
          </foreignObject>

          <foreignObject
            // align="center"
            x="480"
            y="250"
            width="600"
            height="600">
            <div>{Alt()}</div>
          </foreignObject>
          <path
            d="M305.295 103.455V118H303.591L295.665 106.58H295.523V118H293.761V103.455H295.466L303.42 114.903H303.562V103.455H305.295Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  )
}

export default Interface
