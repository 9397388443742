import { useState } from "react"
import { background, info_icon } from "./assets"
import styles from "./message.module.css"

export default function Message({ content, status }) {
  const [end, setEnd] = useState(false)
  let color = "#509bf8"
  if (status === "warning") {
    color = "#ef675a"
  } else if (status === "success") {
    color = "#2dc453"
  }

  return (
    <div className={styles.message_container}>
      <div
        className={styles.message}
        style={{ backgroundColor: color, display: end ? "none" : "flex" }}
        onTouchEnd={(e) => {
          {
            setEnd(true)
            console.log("click")
            e.stopPropagation()
          }
        }}>
        <img className={styles.message_icon} src={info_icon}></img>
        {content}
      </div>
    </div>
  )
}
