import React, { useState, useEffect } from "react";
import {
  LoadScript,
  GoogleMap,
  Polyline,
  MarkerF,
  InfoWindow,
  Polygon,
} from "@react-google-maps/api";

import styles from "./map.module.css";
import { radio_icon, warning_icon_red } from "../../utils/assets";
import {
  get_user_farms,
  get_farm,
  get_scans,
  get_lines,
  get_areas,
  get_multi,
} from "../farms";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  GeoPoint,
  Timestamp,
  arrayUnion,
} from "firebase/firestore";
import { app, auth } from "../../auth/firebase.config.js";

import { onAuthStateChanged } from "firebase/auth";
import Line from "./line";
import Scan from "./scans";
import Info from "./infowindow";
import Controls from "./controls.js";

export default function Map({
  current,
  lineFilter,
  setLineFilter,
  setCurrent,
  setActive,
  setScans,
}) {
  const minZoomVisibility = 15;

  const [farms, setFarms] = useState([]);
  const [infoWindow, setInfoWindow] = useState(null);
  const [scanInfoWindow, setScanInfoWindow] = useState(null);
  const [data, setData] = useState([]); // This is separate to setScan because it is to update the info window
  const [areas, setAreas] = useState([]);
  const [multiline, setMulti] = useState([]);

  // Farm initialisation
  const [maptype, setMapType] = useState("roadmap");
  const [zoom, setZoom] = useState(12);
  const [center, setCenter] = useState({
    lat: -40.981535,
    lng: 173.864733,
  });

  // Line creation
  const [lines, setLines] = useState([]);
  const [currentID, setCurrentID] = useState(null);
  const [lineID, setLineID] = useState(null);
  const [first, setFirst] = useState(null);

  // Farm creation
  const [temp, setTemp] = useState(null);
  const [name, setName] = useState("farm name");
  const [comment, setComment] = useState("");
  const [type, setType] = useState("mussel");
  const [warning, setWarning] = useState(false);

  const get_farms = async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        const data = await get_user_farms(user.uid); // Get farms associated with user id
        setFarms(data);
      }
    });
  };

  useEffect(() => {
    get_farms(); // Collect user info
  }, []);

  const handleMarkerClick = async (e, id) => {
    let current = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setZoom(17);
    setCenter(current);
    setMapType("satellite");
    setCurrentID(id);
    setLineID(null);

    setLines(await get_lines(id));
    setCurrent(await get_farm(id));
    setScans(await get_scans(id)); //Provides list of scan objects given farm id
    setData(await get_scans(id));
    setInfoWindow(id);
    setAreas(await get_areas(id));
    setMulti(await get_multi(id));
  };

  const handleClick = async (e) => {
    // Handle map click - line creation
    let current = { lat: e.latLng.lat(), lng: e.latLng.lng() };

    if (e.domEvent.shiftKey) {
      // Add new line
      if (first === null) {
        setFirst(current);
      } else {
        setFirst(null);
        const name = "New Line";
        await handleNewLine(name, current);
        setLines(await get_lines(currentID));
      }
    } else {
      setTemp(current);
    }
  };

  const handleNewLine = async (name, current) => {
    const db = getFirestore(app);
    try {
      const docRef = await addDoc(collection(db, "farms", currentID, "lines"), {
        name: name,
        start: new GeoPoint(first.lat, first.lng),
        end: new GeoPoint(current.lat, current.lng),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMarkerHover = async (id) => {
    setData(await get_scans(id));
    setInfoWindow(id);
    setActive(null);
    setScanInfoWindow(null);
  };

  function handleZoomChanged() {
    setZoom(this.getZoom());
  }

  function convertGeoPoint(point) {
    return { lat: point._lat, lng: point._long };
  }

  const handleNewFarm = async (e) => {
    e.preventDefault();
    const db = getFirestore(app);
    try {
      const docRef = await addDoc(collection(db, "farms"), {
        name: name,
        location: new GeoPoint(temp.lat, temp.lng),
        type: type,
        warning: warning,
        date_updated: Timestamp.now(),
      });

      // Update user farm access
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log(user.uid);
          await updateDoc(doc(db, "users", user.uid), {
            farms: arrayUnion(...[docRef.id]),
          });
        }
      });

      // Reset temp
      setTemp(null); // Will close info window
      get_farms(); // Collect farm data from database
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewScan = async (e) => {
    e.preventDefault();
    const db = getFirestore(app);
    try {
      const docRef = await addDoc(collection(db, "farms", currentID, "scans"), {
        name: name,
        location: new GeoPoint(temp.lat, temp.lng),
        warning: warning,
        comment: comment,
        time_of_scan: Timestamp.now(),
      });

      // Reset temp
      setTemp(null); // Will close info window
      setScans(await get_scans(currentID));
      setData(await get_scans(currentID));
    } catch (e) {
      console.log(e);
    }
  };

  const handleLineDelete = async (line) => {
    const db = getFirestore(app);
    await deleteDoc(doc(db, "farms", currentID, "lines", line.id));
    setLines(await get_lines(currentID));
  };

  return (
    <div className={styles.app}>
      <Controls maptype={maptype} setMapType={setMapType} />
      <LoadScript
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
        language="en"
      >
        <GoogleMap
          id="map"
          mapContainerClassName={styles.app_map}
          center={center}
          zoom={zoom}
          // onClick={(e) => handleClick(e)} // Farm creator
          onZoomChanged={handleZoomChanged}
          options={{
            streetViewControl: false,
            mapTypeId: maptype,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
        >
          {farms.map((farm) => (
            <>
              <MarkerF
                position={{
                  lat: farm.data.data.location._lat - 0.02 / zoom,
                  lng: farm.data.data.location._long,
                }}
                title={farm.data.name}
                onClick={(e) => handleMarkerClick(e, farm.id)}
                onMouseOver={() => handleMarkerHover(farm.id)}
                name={farm.data.data.name}
                key={farm.id}
                icon={farm.data.data.warning ? warning_icon_red : radio_icon}
                visible={
                  (infoWindow === farm.id && zoom) <= minZoomVisibility
                    ? true
                    : false
                }
              />
              {infoWindow === farm.id && (
                <InfoWindow
                  visible={true}
                  options={{
                    headerContent: farm.data.data.name,
                  }}
                  position={{
                    lat: farm.data.data.location._lat + 0.0002 / zoom,
                    lng: farm.data.data.location._long,
                  }}
                >
                  <Info farm={farm} data={data} />
                </InfoWindow>
              )}
            </>
          ))}

          {areas.map((area) => (
            <Polygon
              path={area.data.nodes.map((node) => convertGeoPoint(node))}
              options={{
                strokeOpacity: 0.8,
                strokeWeight: 5,
                strokeColor: "#15a8ab",
                fillColor: "#008081",
                fillOpacity: 0.2,
              }}
              visible={zoom > minZoomVisibility ? true : false}
            />
          ))}

          {multiline.map((line) => (
            <Polyline
              path={line.data.nodes.map((node) => convertGeoPoint(node))}
              options={{
                strokeWeight: 5,
                strokeOpacity: 1,
                strokeColor: "#15a8ab",
              }}
              visible={zoom > minZoomVisibility ? true : false}
            />
          ))}

          {temp &&
            (!current ? ( //
              <MarkerF
                position={temp}
                icon={{
                  url: radio_icon,
                  anchor: { x: 15, y: 15 },
                }}
              >
                <InfoWindow
                  options={{
                    headerContent: "New Location",
                  }}
                  onCloseClick={() => setTemp(null)}
                  position={{
                    lat: temp.lat + 0.0002 / zoom,
                    lng: temp.lng,
                  }}
                >
                  <form
                    className={styles.new_location}
                    onSubmit={handleNewFarm}
                  >
                    <div className={styles.new_row}>
                      <div className={styles.new_block}>
                        <label>Scan Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Location Name"
                          onChange={(e) => setName(e.target.value)}
                          autocomplete="off"
                          required
                        />
                      </div>

                      <div className={styles.new_block}>
                        <label>Location</label>
                        <p>Lat: {Math.round(temp.lat * 1000) / 1000}</p>
                        <p>Lng: {Math.round(temp.lng * 1000) / 1000}</p>
                      </div>
                    </div>

                    <div className={styles.new_block}>
                      <label>Select Scan Type</label>
                      <select
                        onChange={(e) => setType(e.target.value)}
                        required
                      >
                        <option value={"mussel"}>Mussel</option>
                        <option value={"scallop"}>Scallop</option>
                        <option value={"biosecurity"}>Biosecurity</option>
                      </select>
                    </div>
                    <div className={styles.new_block}>
                      <div className={styles.new_row}>
                        <input
                          className={styles.checkbox}
                          type="checkbox"
                          onChange={(e) => setWarning(e.target.checked)}
                        ></input>
                        <label>Alerts</label>
                      </div>
                    </div>
                    <button type="submit">Add</button>
                  </form>
                </InfoWindow>
              </MarkerF>
            ) : (
              <MarkerF
                position={temp}
                icon={{
                  url: radio_icon,
                  anchor: { x: 15, y: 15 },
                }}
              >
                <InfoWindow
                  options={{
                    headerContent: "New Scan",
                  }}
                  onCloseClick={() => setTemp(null)}
                  position={{
                    lat: temp.lat + 0.0002 / zoom,
                    lng: temp.lng,
                  }}
                >
                  <form
                    className={styles.new_location}
                    onSubmit={handleNewScan}
                  >
                    <div className={styles.new_row}>
                      <div className={styles.new_block}>
                        <label>Scan Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Scan Name"
                          onChange={(e) => setName(e.target.value)}
                          autocomplete="off"
                          required
                        />
                      </div>

                      <div className={styles.new_block}>
                        <label>Location</label>
                        <p>Lat: {Math.round(temp.lat * 1000000) / 1000000}</p>
                        <p>Lng: {Math.round(temp.lng * 1000000) / 1000000}</p>
                      </div>
                    </div>

                    <div className={styles.new_block}>
                      <textarea
                        name="comment"
                        placeholder="Comment"
                        onChange={(e) => setComment(e.target.value)}
                        maxLength={100}
                      />

                      <div className={styles.new_row}>
                        <input
                          className={styles.checkbox}
                          type="checkbox"
                          onChange={(e) => setWarning(e.target.checked)}
                        ></input>
                        <label>Alerts</label>
                      </div>
                    </div>
                    <button type="submit">Add</button>
                  </form>
                </InfoWindow>
              </MarkerF>
            ))}

          <Line
            handleLineDelete={handleLineDelete}
            lines={lines}
            lineID={lineID}
            lineFilter={lineFilter}
            setLineFilter={setLineFilter}
            setLineID={setLineID}
            zoom={zoom}
            minZoomVisibility={minZoomVisibility}
          />

          <Scan
            data={data}
            infoWindow={infoWindow}
            scanInfoWindow={scanInfoWindow}
            setScanInfoWindow={setScanInfoWindow}
            setActive={setActive}
            zoom={zoom}
            minZoomVisibility={minZoomVisibility}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
